<template>
  <div style="position: relative;">
    <CChartLine
      type="line"
      :data="data"
      :options="options"
    />
    <span class="chart-label" v-if='dataObj.commodity_rate.length == 0'>No Data Available</span>
  </div>

</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'

export default {
  name: 'MainChartExample',
  components: {
    CChartLine,
  },
  props: {
    dataObj: {
      type: Object
    }
  },
  data() {
    return {
      data: {},
      options: {},
    }
  },
  created() {

    this.data = {
      labels: this.dataObj.created_at,
      datasets: [
        {
          backgroundColor: 'rgb(228,102,81,0.9)',
          data: this.dataObj.commodity_rate,
        }
      ],
    }

    this.options = {
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        },
        subtitle: {
          display: false
        }
      },
      scales: {
        x: {
          ticks: {
            display: false
          }
        }
      },
      maintainAspectRatio: false,
    }
  }
}
</script>
