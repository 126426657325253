<template>
  <footer id="footer">
    <div class="footer-main">
      <img src="@/assets/images/backgroup/bg-ft.png" alt="" class="bg1">
      <img src="@/assets/images/backgroup/bg-ft2.png" alt="" class="bg2">
      <div style="padding: 0 7%;">

        <div class="">
          <!-- <nav id="main-nav" class="main-nav">
            <ul id="menu-primary-menu" class="menu">
              <li class="menu-item current-menu-item">
                <router-link to="/#home">Home</router-link>
              </li>
              <li class="menu-item ">
                <router-link to="/#about">About Us</router-link>
              </li>
              <li class="menu-item ">
                <router-link to="/#whyus">Why Us?</router-link>
              </li>
              <li class="menu-item ">
                <router-link to="/#affiliate">Affiliate Program</router-link>
              </li>
              <li class="menu-item ">
                <router-link to="/#markets">Markets</router-link>
              </li>
            </ul>
          </nav> -->

          <div class="header__logo">
            <router-link to="/">
              <img src="@/assets/images/logo/logo@2x.png" alt="">
            </router-link>
          </div>
        </div>



      </div>
    </div>
    <div class="footer-bottom">
      <div style="padding: 0 7%;">
        <div class="wrap-fx">
          <div class="Copyright">
            <p>{{ $t('bo.copyrightText') }}</p>
          </div>
          <ul class="list">
            <li>
              <router-link to="/#home">{{ $t("menu.home") }}</router-link>
            </li>
            <li>
              <router-link to="/#about">{{ $t("menu.aboutUs") }}</router-link>
            </li>
            <li>
              <router-link to="/#whyus">{{ $t("menu.whyUs") }}</router-link>
            </li>
            <li>
              <router-link to="/#affiliate">{{ $t("menu.affiliateProgram") }}</router-link>
            </li>
            <li>
              <router-link to="/#markets">{{ $t("menu.markets") }}</router-link>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
import {
  mapState
} from 'vuex'

export default {
  name: 'Footer',
  mounted() {

  }
}
</script>

<style scoped>
#header_main {
  border-bottom: 0;
}

.header__logo {
  text-align: right;
  margin: 0 3%;
}

#footer .footer-main {
  padding: 5% 0;
}
</style>
