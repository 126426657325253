<template>
  <div>

    <div v-if='is_authenticated'>

      <div v-if='!permissionLoad'>
        <AppSidebar v-if='!isIframe'/>
        <AppHeaderIframe v-if='isIframe'/>
        <div class="wrapper wrapper-custom d-flex flex-column bg-light" :class="{'min-vh-100':!isIframe}">
          <AppHeader v-if='!isIframe'/>
          <div class="body flex-grow-1 px-3">
            <CContainer lg>
              <router-view v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                  <component :is="Component" />
                </transition>
              </router-view>
            </CContainer>
          </div>
          <AppFooter v-if='!isIframe'/>
        </div>

        <div
          class="modal-window modal-window__trade modal-window__dark modal-window_statement"
          :class="{'modal-window_statement-history':modalType=='history'}"
          v-if="modal">
          <div class="modal-window__background" @click="closeModal"></div>
          <div>
            <a @click="closeModal" title="Close" class="modal-close">{{ $t('bo.close') }}</a>
            <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;" v-if='componentReload'>
              <CSpinner size="lg"/>
            </div>
            <div class="modal-window__container" v-else>

              <div v-if="modalType == 'history'">
                <hr class="solid">
                <div class="modal-window__flex" style="margin-top: 10px;">
                  <span>{{ $t('bo.date') }} <b>{{ moment(modal_data.created_at).format('DD/MM/YYYY') }}</b></span>
                  <span>{{ $t('bo.orderNumber') }} <b>#{{ modal_data.statement ? modal_data.statement.id : 0 }}</b></span>
                  <span>{{ $t('bo.counter') }} <span>{{ modal_data.statement.commodity.name }} <b style="font-size:10px;margin-left:6px;">({{ modal_data.statement.commodity.code }})</b></span></span>
                </div>
                <hr class="solid">
                <div class="modal-window__flex" style="margin-top: 10px;">
                  <span>{{ $t('bo.open') }} <b>{{ modal_data.hloc.o == null ? '-' : modal_data.hloc.o }}</b></span>
                  <span>{{ $t('bo.close') }} <b>{{ modal_data.hloc.c == null ? '-' : modal_data.hloc.c }}</b></span>
                  <span>{{ $t('bo.low') }} <b>{{ modal_data.hloc.l == null ? '-' : modal_data.hloc.l }}</b></span>
                  <span>{{ $t('bo.high') }} <b>{{ modal_data.hloc.h == null ? '-' : modal_data.hloc.h }}</b></span>
                </div>
                <hr class="solid">
                <div class="modal-window__flex" style="margin-top: 10px;">
                  <span>{{ $t('bo.position') }} <b>{{ $t('bo.buy') }} {{ modal_data.investment.amount }}</b></span>
                  <span>{{ $t('bo.price') }}
                    <b :class="{
                      'red':((modal_data.statement ? modal_data.statement.commodity_rate_start : 0) > (modal_data.statement ? modal_data.statement.commodity_rate_end : 0)),
                      'green':((modal_data.statement ? modal_data.statement.commodity_rate_start : 0) < (modal_data.statement ? modal_data.statement.commodity_rate_end : 0))
                    }">
                      {{ trimBalance(modal_data.statement ? modal_data.statement.commodity_rate_start : 0) }} → {{ trimBalance(modal_data.statement ? modal_data.statement.commodity_rate_end : 0) }}
                    </b>
                  </span>
                  <span>{{ $t('bo.time') }} <b>{{ moment(modal_data.created_at).format('hh:mm:ss') }}</b></span>
                  <span>{{ $t('bo.profitSlLoss') }} <b>${{ trimBalance(modal_data.statement ? modal_data.statement.profit_loss : 0) }}</b></span>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

      <AppLoader v-else />

    </div>

    <div v-else>
      <!-- <body class="header-fixed main" v-if='!isIframe'>
        <div class="wrapper">
          <AppHeaderSec />
          <router-view />
          <AppFooterSec />
        </div>
        <a id="scroll-top"></a>
      </body>

      <div v-if='isIframe'>
        <div class="wrapper wrapper-custom d-flex flex-column bg-light">
          <AppHeaderIframe />
          <div class="body flex-grow-1 px-3">
            <CContainer lg>
              <router-view v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                  <component :is="Component" />
                </transition>
              </router-view>
            </CContainer>
          </div>
        </div>

        <div class="modal-window modal-window__trade modal-window__dark modal-window_statement" v-if="modal">
          <div class="modal-window__background" @click="closeModal"></div>
          <div>
            <a @click="closeModal" title="Close" class="modal-close">{{ $t('bo.close') }}</a>
            <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;" v-if='componentReload'>
              <CSpinner size="lg"/>
            </div>
            <div class="modal-window__container" v-else>

              <div v-if="modalType == 'history'">
                <hr class="solid">
                <div class="modal-window__flex" style="margin-top: 10px;">
                  <span>{{ $t('bo.date') }} <b>{{ moment(modal_data.created_at).format('DD/MM/YYYY') }}</b></span>
                  <span>{{ $t('bo.orderNumber') }} <b>{{ modal_data.statement.id }}</b></span>
                  <span>{{ $t('bo.counter') }} <span>{{ modal_data.statement.commodity.name }} <b style="font-size:10px;margin-left:6px;">({{ modal_data.statement.commodity.code }})</b></span></span>
                </div>
                <hr class="solid">
                <div class="modal-window__flex" style="margin-top: 10px;">
                  <span>{{ $t('bo.open') }} <b>{{ modal_data.hloc.o == null ? '-' : modal_data.hloc.o }}</b></span>
                  <span>{{ $t('bo.close') }} <b>{{ modal_data.hloc.c == null ? '-' : modal_data.hloc.c }}</b></span>
                  <span>{{ $t('bo.low') }} <b>{{ modal_data.hloc.l == null ? '-' : modal_data.hloc.l }}</b></span>
                  <span>{{ $t('bo.high') }} <b>{{ modal_data.hloc.h == null ? '-' : modal_data.hloc.h }}</b></span>
                </div>
                <hr class="solid">
                <div class="modal-window__flex" style="margin-top: 10px;">
                  <span>{{ $t('bo.position') }} <b>{{ $t('bo.buy') }} {{ modal_data.investment.amount }}</b></span>
                  <span>{{ $t('bo.price') }}
                    <b :class="{
                      'red':(modal_data.statement.commodity_rate_start>modal_data.statement.commodity_rate_end),
                      'green':(modal_data.statement.commodity_rate_start<modal_data.statement.commodity_rate_end)
                    }">
                      {{ trimBalance(modal_data.statement.commodity_rate_start) }} → {{ trimBalance(modal_data.statement.commodity_rate_end) }}
                    </b>
                  </span>
                  <span>{{ $t('bo.time') }} <b>{{ moment(modal_data.created_at).format('hh:mm:ss') }}</b></span>
                  <span>{{ $t('bo.profitSlLoss') }} <b>${{ modal_data.statement.profit_loss }}</b></span>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div> -->
    </div>

  </div>
</template>
<script>
import { computed, watch, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from 'vue-router';
import Cookies from 'js-cookie'
import { me } from "@/api/profile";
import { tradingTime } from "@/api/investment"
import { permission } from "@/api/role";
import { CContainer } from '@coreui/vue'
import AppLoader from '@/components/AppLoader.vue'
import AppFooter from '@/components/AppFooter.vue'
import AppFooterSec from '@/components/sub/Footer.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppHeaderIframe from '@/components/AppHeaderIframe.vue'
import AppHeaderSec from '@/components/sub/Header.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import MainChartExample from '@/views/charts/MainChartExample'
import nav from '@/_nav.js'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppFooterSec,
    AppHeader,
    AppHeaderIframe,
    AppHeaderSec,
    AppSidebar,
    AppLoader,
    CContainer,
    MainChartExample,
  },
  setup() {
    const toast = useToast();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    let roleId = ref(-1);
    let roleName = ref(null);
    let permissionLoad = ref(true);
    let isIframe = ref(false);

    watch(() => route.name, (dt) => {
      if (dt == 'Dashboard') {
        getMe()
      } else if (dt == 'Trade Summary Iframe' || dt == 'Trade History Iframe') {
        isIframe.value = true
      } else {
        isIframe.value = false
      }
    })

    onMounted(() => {
      if (!(route.query.token || route.path == '/auth/register')) {
        if (Cookies.get('mxTkn') != undefined) {
          store.commit('setAccessToken', Cookies.get('mxTkn'))

          getMe()
          getTradingTime()
        }

        if (route.name == 'Trade Summary Iframe' || route.name == 'Trade History Iframe') {
          isIframe.value = true
        } else {
          isIframe.value = false

          if (!Cookies.get('mxTkn') || Cookies.get('mxTkn') == undefined) {
            // router.push('/auth/logout')
            permissionLoad.value = false
          }
        }
      }
    })

    async function getMe() {
      try {
        const res = await me()

        if (res.data.code == 200) {
          store.commit('setUserData', res.data.data)
          
          if (res.data.data.is_almost_max || res.data.data.max_cap_message) {
            toast.warning(res.data.data.max_cap_message);
          }
          // if (res.data.data.is_almost_max) {
          //   // toast.warning('Your investment almost reached max capped.');
          // } else if (!res.data.data.investment && res.data.data.prev_investment_id) {
          //   // toast.warning($t('bo.maxCapReached', ['datetime' = 0]));
          //   // toast.warning('Your investment is capped. Renew to withdraw.');
          // }

          roleId.value = res.data.data.role_id
          if (res.data.data.role_id == 0) {
            roleName.value = 'Admin'
          } else if (res.data.data.role_id == 1) {
            roleName.value = 'Leader'
          } else {
            roleName.value = 'Client'
          }
          getPermission(roleId.value)
        } else {
          toast.error(res.data.message)
        }

      } catch (err) {
        console.log(err);
        
        toast.error(err.message)
      }
    }

    async function getPermission(roleId) {
      try {
        let params = {
          role_id: roleId
        }
        const res = await permission({ params })

        if (res.data.code == 200) {
          store.commit('setRoleAccess', res.data.data)
          permissionLoad.value = false
        } else {
          toast.error(res.data.message)
        }

      } catch (err) {
        toast.error(err)
      }
    }

    async function getTradingTime() {
      try {
        const res = await tradingTime()

        if (res.data.code == 200) {
          store.commit('setTradingTime', res.data.data)
        } else {
          toast.error(res.data.message)
        }

      } catch (err) {
        toast.error(err)
      }
    }

    return {
      toast,
      store,
      route,
      permissionLoad,
      isIframe,
      roleId,
      roleName,
      is_authenticated: computed(() => store.state.is_authenticated),
    }
  },
  data() {
    return {
      modal: false,
      modal_data: {},
      origin_data: {},
      componentReload: false,
      modalType: ''
    }
  },
  watch: {
    permissionLoad() {
      this.setPermission()
    }
  },
  mounted() {
    this.emitter.on("setModalDisplay", (data) => {
      this.origin_data = data
      this.modalType = data.modalType
      this.modal = true;
      this.setModalDisplay(data)
      setTimeout(() => {
        this.componentReload = false
      },1000)
    });
  },
  methods: {
    setModalDisplay(data) {
      this.modal_data = data
    },
    closeModal() {
      this.modal = false
    },
    // Page permission
    setPermission() {
      nav.forEach((item, i) => {

        // Permission for User Wallet
        if (item.name == 'Deposit' && !this.rolePermission.includes('deposit_view')) {
          item.visible = false
        } else if (item.name == 'Deposit' && this.rolePermission.includes('deposit_view') && (this.roleId == 0)) {
          item.visible = false
        }
        if (item.name == 'Withdrawal' && !this.rolePermission.includes('withdrawal_view')) {
          item.visible = false
        } else if (item.name == 'Withdrawal' && this.rolePermission.includes('withdrawal_view') && (this.roleId == 0)) {
          item.visible = false
        }
        if (item.name == 'Wallet' && !this.rolePermission.includes('deposit_view') && !this.rolePermission.includes('withdrawal_view') && (this.roleId == 1 || this.roleId == 2)) {
          item.visible = false
        } else if (item.name == 'Wallet' && (this.roleId == 0)) {
          item.visible = false
        }

        // Permission for User ROI
        if (item.name == 'Activate Trade' && !this.rolePermission.includes('roi_claim')) {
          item.visible = false
        }

        // Permission for Admin Withdrawal List
        if (item.name == 'Withdrawal List' && !this.rolePermission.includes('withdrawal_view')) {
          item.visible = false
        } else if (item.name == 'Withdrawal List' && this.rolePermission.includes('withdrawal_view') && (this.roleId == 1 || this.roleId == 2)) {
          item.visible = false
        }
        if (item.name == 'transaction' && this.rolePermission.includes('withdrawal_view') && (this.roleId == 1 || this.roleId == 2)) {
          item.visible = false
        }

        // Permission for Admin Deposit List
        if (item.name == 'Deposit List' && !this.rolePermission.includes('deposit_view')) {
          item.visible = false
        } else if (item.name == 'Deposit List' && this.rolePermission.includes('deposit_view') && (this.roleId == 1 || this.roleId == 2)) {
          item.visible = false
        }
        if (item.name == 'transaction' && this.rolePermission.includes('deposit_view') && (this.roleId == 1 || this.roleId == 2)) {
          item.visible = false
        }

        // Permission for User Investment
        if (item.name == 'Investment' && !this.rolePermission.includes('roi_view')) {
          item.visible = false
        }

        // Permission for User Withdrawal History
        if (item.name == 'Withdraw History' && !this.rolePermission.includes('withdrawal_view')) {
          item.visible = false
        } else if (item.name == 'Withdraw History' && this.rolePermission.includes('withdrawal_view') && (this.roleId == 1 || this.roleId == 2)) {
          item.visible = false
        }

        // Permission for Admin Affiliate
        if (item.name == 'Affiliate List' && !this.rolePermission.includes('user_view')) {
          item.visible = false
        }
        if (item.name == 'Data' && !this.rolePermission.includes('user_view')) {
          item.visible = false
        }

        // Permission for Admin Setting
        if (item.name == 'Setting' && !this.rolePermission.includes('setting_view')) {
          item.visible = false
        }
        if (item.name == 'Permission' && !this.rolePermission.includes('role_view')) {
          item.visible = false
        }
        if (item.name == 'Contract' && !this.rolePermission.includes('contract_view')) {
          item.visible = false
        }
        if (item.name == 'Admin Notification' && (this.roleId !== 0)) {
          item.visible = false
        }
        if (item.name == 'Global Reward' && !this.rolePermission.includes('global_reward_view')) {
          item.visible = false
        }

        // Permission for Non Admin Trade Summary
        if (item.name == 'Trade Summary' && this.roleId == 0) {
          item.visible = true
        }

        if (item.name == 'Trade Details' && this.roleId == 0) {
          item.visible = true
        }
      });
    },
  }
}
</script>
