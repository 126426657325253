import store from '@/store'
import Web3 from 'web3'
import WalletConnectProvider from "@walletconnect/web3-provider"
import { useI18n } from "vue-i18n";
import {
  useToast
} from "vue-toastification"
import moment from 'moment'

const { VUE_APP_BASE_URL } = process.env
export default {
  data: function () {
    return {
      chainList: [{
        id: 1,
        name: 'Ethereum Mainnet',
        chain: 'ETH',
      },
      {
        id: 3,
        name: 'Ethereum Testnet Ropsten',
        chain: 'ETH',
      },
      {
        id: 4,
        name: 'Ethereum Testnet Rinkeby',
        chain: 'ETH',
      },
      {
        id: 42,
        name: 'Ethereum Testnet Kovan',
        chain: 'ETH',
      },
      {
        id: 5,
        name: 'Ethereum Testnet Görli',
        chain: 'ETH',
      },
      {
        id: 56,
        name: 'Binance Smart Chain Mainnet',
        chain: 'BSC',
      },
      {
        id: 86,
        name: 'Binance Smart Chain Mainnet(android)', // Defined outside nvm
        chain: 'BSC',
      },
      {
        id: 97,
        name: 'Binance Smart Chain Testnet',
        chain: 'BSC',
      },
      ],
      toast: useToast(),
      moment: moment,
      baseUrl: VUE_APP_BASE_URL,
      welcomeMessage: {
        title: 'Welcome to EMEX TRADER.',
        message: `We’re excited to have you join our community! As you explore our platform, you’ll find a variety of tools and resources designed to enhance your experience.

                  If you have any questions or need assistance, our support team is here to help.

                  Thank you for choosing us, and enjoy your journey!`,
        titleCn: '欢迎来到 EMEX TRADER',
        messageCn: `我们很高兴您能加入我们的社区！在您探索我们的平台时，您会发现各种旨在增强您的体验的工具和资源。

                  如果您有任何问题或需要帮助，我们的支持团队将随时为您提供帮助。

                  感谢您选择我们，祝您旅途愉快！	`,
      }
    }
  },
  computed: {
    rolePermission() {
      return store.state.rlAccess
    },
  },
  methods: {
    async walletConnect() {
      try {
        //  Create WalletConnect Provider
        const provider = new WalletConnectProvider({
          rpc: {
            1: "https://mainnet.infura.io/v3/",
            56: "https://bsc-dataseed.binance.org/",
          },
          infuraId: '4ec235cb31904878b523ec43e3f4f339'
        });

        //  Enable session (triggers QR Code modal)
        await provider.enable();

        //  Create Web3
        const instance = new Web3(provider);

        // Get necessary info on your node
        const networkId = await instance.eth.net.getId();
        const coinbase = await instance.eth.getCoinbase();
        const balance = 0;
        const networkData = this.chainList.find(function (e) {
          return networkId === e.id
        })
        const networkName = networkData.chain

        store.commit('setWalletConnect', 'WalletConnect')

        let storeWallet = [
          networkId,
          networkData,
          networkName,
          coinbase,
          balance
        ]

        store.commit('setWalletData', storeWallet)

      } catch (e) {

      }
    },
    async connectMetamask() {
      // // Check for web3 provider
      const {
        ethereum
      } = window;
      const {
        host,
        pathname
      } = window.location;

      try {
        // Ask to connect
        await ethereum.send('eth_requestAccounts');
        const instance = new Web3(ethereum)

        // Get necessary info on your node
        const networkId = await instance.eth.net.getId();
        const coinbase = await instance.eth.getCoinbase();
        const balance = 0;
        const networkData = this.chainList.find(function (e) {
          return networkId === e.id
        })
        const networkName = networkData.chain

        store.commit('setWalletConnect', 'MetaMask')

        let storeWallet = [
          networkId,
          networkData,
          networkName,
          coinbase,
          balance
        ]

        store.commit('setWalletData', storeWallet)

      } catch (error) {
        // User denied account access
        this.toast.error('User denied access')
        return;
      }
    },
    async connectTronlink() {
      if (window.tronWeb) {
        //checking if wallet injected
        if (window.tronWeb.ready) {
          let tempBalance = await this.tronGetBalance();

          if (!tempBalance.balance) {
            tempBalance.balance = 0;
          }

          store.commit('setWalletConnect', 'TronLink')

          let storeWallet = [
            null,
            null,
            'TRON',
            window.tronWeb.defaultAddress.base58,
            tempBalance.balance / 1000000
          ]

          store.commit('setWalletData', storeWallet)

        } else {
          //we have wallet but not logged in
          this.toast.error('Please Login on your Dapp to connect!')
        }
      } else {
        //wallet is not detected at all
        this.toast.error('Please open ACT Capital website from your Dapp to connect with tronlink.')
      }
    },
    async tronGetBalance() {
      if (window.tronWeb && window.tronWeb.ready) {
        let walletBalances = await window.tronWeb.trx.getAccount(
          window.tronWeb.defaultAddress.base58
        );
        return walletBalances;
      } else {
        return 0;
      }
    },
    disconnectWallet() {
      store.commit('setWalletDisconnect')
    },
    elipsisAddress(address) {
      return address.substring(0, 8) + '...' + address.substring(address.length - 4)
    },
    generalNumberFormat(number = 0) {
      return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    NumbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    NumbersWithMinus(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    minus(val1, val2) {
      return window.Decimal(Number(val1)).minus(Number(val2))
    },
    trimBalance(value) {
			const firstVal = value != null ? value.toString().split('.')[0] : '0'
			const secVal = value != null ? value.toString().split('.')[1] : ''

			return firstVal + (secVal == '' || secVal == null ? '' : '.' + secVal.substring(0, 4))
		},
    getWelcomeMessage(dt, lang) {
      var $self = this;
      return dt.map((e) => {
        if(e.notification.title == "register_title") {
          var temp = e;
          temp.notification.title = lang == 'cn' ? $self.welcomeMessage.titleCn : $self.welcomeMessage.title;
          temp.notification.message = lang == 'cn' ? $self.welcomeMessage.messageCn : $self.welcomeMessage.message;
          console.log(temp);
          return temp

        }
        return e;
      });
    }
  }
}
