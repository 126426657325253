import axios from 'axios'

export function tier() {
  return axios.get("api/affiliate/tier")
}

export function plan() {
  return axios.get("api/affiliate/roi/plan")
}

export function start(payload) {
  return axios.post("api/affiliate/roi", payload)
}

export function upgrade(payload) {
  return axios.post("api/affiliate/roi/upgrade", payload)
}

export function transfer(payload) {
  return axios.post("api/affiliate/roi/transfer", payload)
}

export function claim() {
  return axios.get("api/affiliate/roi/claim")
}

export function tradingTime() {
  return axios.get("api/affiliate/trading-time")
}

export function active() {
  return axios.get("api/affiliate/roi")
}
