<template>
  <CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" @visible-change="
    (event) =>
      $store.commit({
        type: 'updateSidebarVisible',
        value: event,
      })
  ">
    <CSidebarBrand>
      <CImage custom-class-name="sidebar-brand-full" width="120" :src="require('@/assets/images/logo@2x.png')" />
      <CIcon custom-class-name="sidebar-brand-narrow" :icon="sygnet" :height="35" />
    </CSidebarBrand>
    <AppSidebarNav />
    <!-- <div class="emex-wrapper" v-if="role != 'admin'">
      <img src="@/assets/images/logo0.png" class="emexItem" alt="emex logo">
      <ul class="sidebar-nav">
        <li class="nav-item" :id="(item.id ? item.id : '')" v-for="(item, index) in nav" :key="index">
          <a class="active nav-link" aria-current="page" @click='claimRewards()' v-if='!item.to'>
            <CIcon class="nav-icon" :icon="item.icon" size="lg" />
            {{ claim_load? 'Loading...': $t('bo.' + item.code) }}
          </a>
          <router-link class="nav-link" :to="item.to" v-else>
            <CIcon class="nav-icon" :icon="item.icon" size="lg" />
            {{ $t('bo.'+ item.code) }}
          </router-link>
        </li>
      </ul>
    </div> -->
    <CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleSidebar')" />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore, mapState } from 'vuex'
import store from '@/store'
import {
  useToast
} from "vue-toastification"
import { claim } from "@/api/investment"
import customToast from "@/components/toast/customToastConfirm.vue"
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import logoImage from '@/assets/images/logo0.png'
import { sygnet } from '@/assets/brand/sygnet'
import nav from '@/_nav-emex.js'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
  data() {
    return {
      nav: nav,
      toast: useToast(),
      claim_load: false,
    }
  },
  computed: {
    ...mapState([
      'role',
    ]),
    tradingTime() {
      return store.state.trading_time
    },
  },
  methods: {
    async claimRewards() {
      if (this.claim_load) {
        return false
      }

      if (this.tradingTime.isTradingTime) {
        this.toast.error('Unable to activate trade during trading hours.')
        return false
      }

      let claimToast = this.toast({
        props: {
          message: this.$t('bo.activateTradeFor'),
          labelConfirm: this.$t('bo.confirm'),
          labelClose: this.$t('bo.close'),
        },
        closeButton: false,
        component: customToast,
        listeners: {
          confirm: async () => {
            this.claim_load = true

            try {
              const res = await claim();

              if (res.data.code == 200) {
                this.toast.success(res.data.message)
              } else {
                this.toast.error(res.data.message)
              }
              this.claim_load = false
            } catch (e) {
              this.toast.error(e.message)
              this.claim_load = false
            }
          },
          cancel: async() => {
            this.toast.dismiss(claimToast);
          }
        }
      }, {
        icon: false,
        rtl: false
      })
    }
  }
}
</script>
