<template>
  <header id="header_main" class="header" v-show="!base_modal_appear">
    <div class="container">
      <div id="site-header-inner">
        <div class="header__logo">
          <router-link to="/">
            <img src="@/assets/images/logo/logo.png" alt="">
          </router-link>
        </div>
        <nav id="main-nav" class="main-nav main-nav-custom" :class="$i18n.locale === 'vn' ? 'col-6' : ''">
          <ul id="menu-primary-menu" class="menu">
            <li class="menu-item current-menu-item">
              <router-link to="/#home">{{ $t("menu.home") }}</router-link>
            </li>
            <li class="menu-item ">
              <router-link to="/#about">{{ $t("menu.aboutUs") }}</router-link>
            </li>
            <li class="menu-item ">
              <router-link to="/#whyus">{{ $t("menu.whyUs") }}</router-link>
            </li>
            <li class="menu-item ">
              <router-link to="/#affiliate">{{ $t("menu.affiliateProgram") }}</router-link>
            </li>
            <li class="menu-item ">
              <router-link to="/#markets">{{ $t("menu.markets") }}</router-link>
            </li>
            <li class="menu-item menu-item-auth">
              <router-link to="/auth/login">{{ $t("menu.login") }}</router-link>
            </li>
            <li class="menu-item menu-item-auth">
              <router-link to="/auth/register">{{ $t("menu.getStarted") }}</router-link>
            </li>
          </ul>
        </nav><!-- /#main-nav -->
        <ul id="menu-primary-menu" class="menu menu-lang-mobile__ver">
          <li class="nav-item btn-group" @mouseover="isDropdownOpened = true" @mouseleave="isDropdownOpened = false" ref="dropdown">
            <div class="dropdown__lang dropdown-toggle">
              <i class="fi" :class="'fi-' + selectedLanguage"></i>
            </div>
            <div class="dropdown__list dropdown-menu dropdown-menu-end dropdown-menu-lg-start p-0" :class="{'show':isDropdownOpened}">
              <a href="#" class="dropdown-item" :class="selectedLanguage === 'us' ? 'active': ''" @click.prevent="changeLanguage('us')">
                <i class="fi fi-us mr-2"></i> English
              </a>
              <a href="#" class="dropdown-item" :class="selectedLanguage === 'vn' ? 'active': ''" @click.prevent="changeLanguage('vn')">
                <i class="fi fi-vn mr-2"></i> Vietnam
              </a>
              <a href="#" class="dropdown-item" :class="selectedLanguage === 'cn' ? 'active': ''" @click.prevent="changeLanguage('cn')">
                <i class="fi fi-cn mr-2"></i> Chinese
              </a>
              <a href="#" class="dropdown-item" :class="selectedLanguage === 'id' ? 'active': ''" @click.prevent="changeLanguage('id')">
                <i class="fi fi-id mr-2"></i> Indonesia
              </a>
            </div>
          </li>
        </ul>
        <div class="auth-section">
          <ul id="menu-primary-menu" class="menu menu-lang-mobile">
            <li class="nav-item btn-group" @mouseover="isDropdownOpened = true" @mouseleave="isDropdownOpened = false" ref="dropdown">
              <div class="dropdown__lang dropdown-toggle">
                <i class="fi" :class="'fi-' + selectedLanguage"></i>
              </div>
              <div class="dropdown__list dropdown-menu dropdown-menu-end dropdown-menu-lg-start p-0" :class="{'show':isDropdownOpened}">
                <a href="#" class="dropdown-item" :class="selectedLanguage === 'us' ? 'active': ''" @click.prevent="changeLanguage('us')">
                  <i class="fi fi-us mr-2"></i> English
                </a>
                <a href="#" class="dropdown-item" :class="selectedLanguage === 'vn' ? 'active': ''" @click.prevent="changeLanguage('vn')">
                  <i class="fi fi-vn mr-2"></i> Vietnam
                </a>
                <a href="#" class="dropdown-item" :class="selectedLanguage === 'cn' ? 'active': ''" @click.prevent="changeLanguage('cn')">
                  <i class="fi fi-cn mr-2"></i> Chinese
                </a>
                <a href="#" class="dropdown-item" :class="selectedLanguage === 'id' ? 'active': ''" @click.prevent="changeLanguage('id')">
                  <i class="fi fi-id mr-2"></i> Indonesia
                </a>
              </div>
            </li>
          </ul>
          <router-link to="/auth/login" class="tf-button btn-effect me-3">
            <span class="boder-fade"></span>
            <span class="effect">{{ $t("menu.login") }}</span>
          </router-link>
          <router-link to="/auth/register" class="tf-button btn-effect">
            <span class="boder-fade"></span>
            <span class="effect">{{ $t("menu.getStarted") }}</span>
          </router-link>
        </div>
        <div class="mobile-button"><span></span></div><!-- /.mobile-button -->
      </div>
    </div>
  </header>
</template>

<script>
import {
  mapState
} from 'vuex'

export default {
  name: 'Header',
  data: function() {
    return {
      selectedLanguage: 'us',
      isDropdownOpened: false,
    }
  },
  computed: {
    ...mapState([
      "language",
      "base_modal_appear"
    ])
  },
  mounted() {
    ;
    (function ($) {
      "use strict";

      // Mobile Navigation
      var mobileNav = function () {
        var mobile = window.matchMedia("(max-width: 1249px)");
        var wrapMenu = $("#site-header-inner");
        var navExtw = $(".nav-extend.active");
        var navExt = $(".nav-extend.active").children();

        responsivemenu(mobile);

        mobile.addListener(responsivemenu);

        function responsivemenu(mobile) {
          if (mobile.matches) {
            $("#main-nav")
              .attr("id", "main-nav-mobi")
              .appendTo("#header_main")
              .hide()
              .children(".menu")
              .append(navExt)
              .find("li:has(ul)")
              .children("ul")
              .removeAttr("style")
              .hide()
              .before('<span class="arrow"></span>');
          } else {
            $("#main-nav-mobi")
              .attr("id", "main-nav")
              .removeAttr("style")
              .insertAfter(".header .header__logo")
              .find(".ext")
              .appendTo(navExtw)
              .parent()
              .siblings("#main-nav")
              .find(".sub-menu")
              .removeAttr("style")
              .prev()
              .remove();

            $(".mobile-button").removeClass("active");
            $(".sub-menu").css({
              display: "block"
            });
          }
        }
      };

      // Dom Ready
      $(function () {
        mobileNav();
      });

    })(jQuery);
    this.$i18n.locale = this.language
    this.selectedLanguage = this.language
  },
  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale
      this.$store.dispatch('setLanguage', locale);
      this.selectedLanguage = locale
      this.isDropdownOpened = false
    },
    test() {
      alert('test')
    }
  }
}
</script>

<style scoped>
  #header_main {
    border-bottom: 0;
  }
  .menu-item-auth {
    display: none;
  }
  .auth-section {
    position: relative;
    display: flex;
    align-items: center;
  }
  .dropdown__lang {
    background: transparent;
    border: 0;
  }
  .dropdown__list {
    background: #141a3175;
    top: 28px;
    right: 0;
  }
  .main-nav.main-nav-custom {
    position: relative;
    left: 0;
    transform: none;
    display: block;
    margin: 0 auto;
  }
  .menu-lang-mobile {
    display: block;
    position: relative;
    margin-left: 0;
    right: 20px;
  }
  .menu-lang-mobile .dropdown__list {
    background: white;
  }
  .menu-lang-mobile__ver {
    display: none;
  }

  @media only screen and (min-width:1367px) {
    .auth-section {
      position: relative;
      right: -11%;
    }

    .header .container {
      min-width: 100% !important;
    }

    .header .container .auth-section {
      right: 0% !important;
    }
  }

  @media only screen and (max-width:1249px) {
    #main-nav {
      display: none;
    }
    .auth-section {
      margin-left: auto;
    }
  }

  @media only screen and (max-width:991px) {
    .btn-group-lang {
      display: none;
    }
  }

  /*Mobile Query*/
  @media only screen and (max-width:480px) {
    .menu-lang-mobile__ver {
      display: block;
      margin-left: auto;
      position: relative;
      right: 20px;
    }
    .menu-lang-mobile__ver .dropdown__list {
      background-color: white;
    }
    .auth-section {
      display: none;
    }
    .menu-item-auth {
      display: list-item;
    }
    .menu-item-auth a {
      color: #14C2A3 !important;
      font-weight: 700;
      text-decoration: underline;
    }
  }
</style>
