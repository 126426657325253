export default [
  {
    component: 'CNavItem',
    name: 'Activate Trade',
    code: 'activateTrade',
    icon: 'cil-gift',
    visible: true,
    id: 'ActivateTrade'
  },
  {
    component: 'CNavItem',
    name: 'Trade Summary',
    code: 'tradeSummary',
    to: '/trade/summary',
    icon: 'cil-graph',
    class: 'emexTrade',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Trade History',
    code: 'tradeHistory',
    to: '/trade/history',
    icon: 'cil-history',
    class: 'emexTrade',
    visible: true,
  },
]
