export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    code: 'dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Investment',
    code: 'investment',
    to: '/investment',
    icon: 'cil-diamond',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Affiliate Tree',
    code: 'affiliateTree',
    to: '/affiliate-tree',
    icon: 'cil-sitemap',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Affiliate Report',
    code: 'affiliateReport',
    to: '/downline',
    icon: 'cil-line-style',
    visible: true,
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Withdraw History',
  //   to: '/withdrawal-list',
  //   icon: 'cil-money',
  //   visible: true,
  // },
  {
    component: 'CNavItem',
    name: 'Trade Details',
    code: 'tradeDetails',
    to: '/trade/details',
    icon: 'cil-graph',
    visible: false,
  },
  {
    component: 'CNavTitle',
    name: 'Wallet',
    code: 'wallet',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Deposit',
    code: 'deposit',
    to: '/deposit',
    icon: 'cil-money',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Withdrawal',
    code: 'withdrawal',
    to: '/withdrawal',
    icon: 'cil-wallet',
    visible: true,
  },
  {
    component: 'CNavTitle',
    name: 'Data',
    code: 'data',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Affiliate List',
    code: 'affiliateList',
    to: '/affiliate',
    icon: 'cil-people',
    visible: true,
  },
  {
    component: 'CNavTitle',
    name: 'transaction',
    code: 'transaction',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Deposit List',
    code: 'depositList',
    to: '/admin/deposit-list',
    icon: 'cil-money',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Withdrawal List',
    code: 'withdrawalList',
    to: '/admin/withdrawal-list',
    icon: 'cil-money',
    visible: true,
  },
  {
    component: 'CNavTitle',
    name: 'System',
    code: 'system',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'System Investment',
    code: 'systemInvestment',
    to: '/system/investment',
    icon: 'cil-settings',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Reward Level',
    code: 'rewardLevel',
    to: '/system/reward-level',
    icon: 'cil-settings',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Reward Level Gen',
    code: 'rewardLevelGen',
    to: '/system/reward-level-gen',
    icon: 'cil-settings',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Community Bonus',
    code: 'communityBonus',
    to: '/system/community-bonus',
    icon: 'cil-settings',
    visible: false,
  },
  {
    component: 'CNavItem',
    name: 'Global Reward',
    code: 'globalReward',
    to: '/system/global-reward',
    icon: 'cil-settings',
    visible: false,
  },
  {
    component: 'CNavItem',
    name: 'Contract',
    code: 'contract',
    to: '/system/contract',
    icon: 'cil-settings',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Admin Notification',
    code: 'notification',
    to: '/system/notification',
    icon: 'cil-settings',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Permission',
    code: 'permission',
    to: '/system/permission',
    icon: 'cil-settings',
    visible: true,
  },
  {
    component: 'CNavItem',
    name: 'Setting',
    code: 'setting',
    to: '/system/setting',
    icon: 'cil-settings',
    visible: true,
  },
  // {
  //   component: 'CNavTitle',
  //   name: 'Emex',
  //   logo: true,
  //   visible: true,
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Activate Trade',
  //   icon: 'cil-gift',
  //   to: '/activate',
  //   visible: true,
  //   id: 'ActivateTrade'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Trade Summary',
  //   to: '/trade/summary',
  //   icon: 'cil-graph',
  //   class: 'emexTrade',
  //   visible: true,
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Trade History',
  //   to: '/trade/history',
  //   icon: 'cil-history',
  //   class: 'emexTrade',
  //   visible: true,
  // },
]
