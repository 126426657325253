import axios from 'axios'

export function permissionList(payload) {
  return axios.get("api/affiliate/permission", payload)
}

export function permission(payload) {
  return axios.get("api/affiliate/role-permission", payload)
}

export function addPermission(payload) {
  return axios.post("api/affiliate/role-permission", payload)
}
