<template>
  <div class="container container-custom">
    <span>{{ tradingTime.upcomingTradingTime == undefined ? '...' : `${message} ${tradingTime.upcomingTradingTime}` }} </span>
    <div class="action-container">
      <button
        class="action"
        @click="$emit('confirm')"
      >{{ labelConfirm }}</button>
      <button
        class="action danger"
        @click="$emit('close')"
      >{{ labelClose }}</button>
    </div>
  </div>
</template>

<script>
import { tradingTime } from "@/api/investment"
export default {
  props: {
    message: String,
    labelConfirm: String,
    labelClose: String
  },
  data() {
    return {
      tradingTime: {}
    }
  },
  async mounted() {
    const res = await tradingTime()

    if (res.data.code == 200) {
      this.tradingTime = res.data.data
    } else {
      console.log(res.data.data)
    }
  }
};
</script>

<style scoped>
  .container-custom {
    margin-right: 100px;
  }
  .container-custom .action-bu {
    position: absolute;
    display: inline-flex;
    height: 100%;
    top: 0;
    background: transparent;
    align-items: center;
    font-size: 14px;
    margin: 0 6px 0 23px;
    padding: 0 11px;
    border: 1px solid #ffffff36;
    border-bottom: 0;
    border-top: 0;
    color: #ffffffc2;
    transition: all 0.5s ease;
  }

  .container-custom .action-container {
    align-items: center;
    font-size: 14px;
    margin-top: 20px;
  }
  .container-custom .action {
    display: inline-block;
    background: transparent;
    padding: 0 11px;
    border: 1px solid #ffffff36;
    color: #ffffffc2;
    padding: 10px;
    transition: all 0.5s ease;
    margin-right: 10px;
  }
  .container-custom .action.danger {
    color: #ffc107;
    border-color: #ffc107;
  }
  .container-custom .action:hover {
    background: #0054a7;
  }
</style>
