import {
  h,
  resolveComponent
} from 'vue'
import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import middlewarePipeline from "@/middleware/middleware-pipeline";

import DefaultLayout from '@/layouts/DefaultLayout'
import BlankLayout from '@/layouts/BlankLayout'

const routes = [{
  path: '/',
  name: 'Home',
  component: DefaultLayout,
  children: [{
    path: '/',
    name: 'Index',
    component: () => import('@/views/index.vue'),
    meta: {
      middleware: [guest]
    },
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: () => import('@/views/auth/register.vue'),
    meta: {
      middleware: [guest]
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/investment',
    name: 'Investment',
    component: () => import('@/views/investment/investment.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: () => import('@/views/wallet/deposit.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: () => import('@/views/wallet/withdrawal.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/change-pwd',
    name: 'Change Password',
    component: () => import('@/views/profile/changePwd.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/notification',
    name: 'User Notification',
    component: () => import('@/views/notification.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/affiliate',
    name: 'Affiliate',
    component: () => import('@/views/affiliate/affiliateList.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/affiliate-tree',
    name: 'Affiliate Tree',
    component: () => import('@/views/affiliate/affiliateTree.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/downline',
    name: 'Affiliate Report',
    component: () => import('@/views/downline/index.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/withdrawal-list',
    name: 'Withdraw History',
    component: () => import('@/views/transactionUser/withdrawalList.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/admin/deposit-list',
    name: 'Deposit List',
    component: () => import('@/views/transactionAdmin/depositList.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/admin/withdrawal-list',
    name: 'Withdrawal List',
    component: () => import('@/views/transactionAdmin/withdrawalList.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: '404',
    component: () => import('@/views/pages/Page404.vue'),
  },
  {
    path: '/statement-report',
    name: 'Statement Report',
    component: () => import('@/views/report/statement.vue'),
    meta: {
      middleware: [auth]
    },
  },
  {
    path: '/system',
    name: 'System',
    children: [
      {
        path: 'investment',
        name: 'System Investment',
        component: () => import('@/views/system/investment/index'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'investment/:id',
        name: 'System Investment Detail',
        component: () => import('@/views/system/investment/detail'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'reward-level',
        name: 'Reward Level',
        component: () => import('@/views/system/rewardLevel/index'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'reward-level/:id',
        name: 'Reward Level Detail',
        component: () => import('@/views/system/rewardLevel/detail'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'reward-level-gen',
        name: 'Reward Level Gen',
        component: () => import('@/views/system/rewardLevelGen/index'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'reward-level-gen/:id',
        name: 'Reward Level Gen Detail',
        component: () => import('@/views/system/rewardLevelGen/detail'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'community-bonus',
        name: 'Community Bonus',
        component: () => import('@/views/system/communityBonus'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'global-reward',
        name: 'Global Reward',
        component: () => import('@/views/system/globalReward'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import('@/views/system/setting'),
        meta: {
          middleware: [auth]
        },
      },
      // for admin role
      {
        path: 'notification',
        name: 'Notification',
        component: () => import('@/views/system/notification'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'permission',
        name: 'Permission',
        component: () => import('@/views/system/permission'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'contract',
        name: 'Contract',
        component: () => import('@/views/system/contract'),
        meta: {
          middleware: [auth]
        },
      },
    ],
  },
  {
    path: '/trade',
    name: 'Trade',
    children: [
      {
        path: 'history',
        name: 'Trade History',
        component: () => import('@/views/trade/history'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'summary',
        name: 'Trade Summary',
        component: () => import('@/views/trade/summary'),
        meta: {
          middleware: [auth]
        },
      },
      {
        path: 'details',
        name: 'Trade Details',
        component: () => import('@/views/trade/details'),
        meta: {
          middleware: [auth]
        },
      },
    ],
  },
  {
    path: '/iframe/trade-history',
    name: 'Trade History Iframe',
    // component: () => import('@/views/maintenance/index'),
    component: () => import('@/views/trade/history_iframe'),
  },
  {
    path: '/iframe/trade-summary',
    name: 'Trade Summary Iframe',
    // component: () => import('@/views/maintenance/index'),
    component: () => import('@/views/trade/summary_iframe'),
  },
  ],
},
{
  path: '/auth/login',
  name: 'Login',
  component: () => import('@/views/auth/login.vue'),
  meta: {
    middleware: [guest]
  },
},
{
  path: '/verify-account',
  name: 'Verify Account',
  component: () => import('@/views/auth/verify-account.vue'),
},
{
  path: '/auth/logout',
  name: 'Logout',
  component: () => import('@/views/auth/logout.vue'),
},
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return {
      top: 0
    }
  },
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    }
    return { x: 0, y: 0 };  // Go to the top of the page if no hash
  },
})

router.beforeEach((to, from, next) => {

  /** Navigate to next if middleware is not applied */
  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default router
